import React from 'react';
import MarketingHeader from './MarketingHeader';
import HeroSection from './HeroSection';
import BigScreenshotFeature from './BigScreenshotFeature';
import RightScreenshotFeature from './RightScreenshotFeature';
import LeftScreenshotFeature from './LeftScreenshotFeature';
import ContainedFeature from './ContainedFeature'
import CTALeft from './CTALeft';
import CTALightBrand from './CTALightBrand'
import TestimonialGrid from './TestimonialGrid';
import SupportiveFAQ from './SupportiveFAQ'
import Footer from './Footer'
import WhiteNewsletter from './WhiteNewsletter';
import GradientBanner from './GradientBanner';

// Components

// Development Landing
function Landing() {
  return <div><MarketingHeader /><HeroSection /><GradientBanner /><Footer /></div>
}

// Production Landing
// function Landing() {
//  return <div><MarketingHeader /><HeroSection /><BigScreenshotFeature /><RightScreenshotFeature /><LeftScreenshotFeature /><ContainedFeature /><CTALeft /><TestimonialGrid /><SupportiveFAQ /><CTALightBrand /><Footer /></div>
// }

export default Landing