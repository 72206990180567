// To compile app and deploy to Firebase run the following lines
// npm run build
// firebase deploy

// Imports

import './firebase';
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';


import './App.css';
import GradientBanner from './components/GradientBanner'
import Landing from './components/Landing'
import SignInSimple from './components/SignInSimple';

import './index.css';

// Components

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<><Landing /></>} />
        <Route path="/signin" element={<SignInSimple />} />
        <Route path="/#newsletter" element={<SignInSimple />} />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;