import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDHDcSM7uen_mmpetPgsgE7fkPsm-1dOS0",
  authDomain: "mealmate-409617.firebaseapp.com",
  projectId: "mealmate-409617",
  storageBucket: "mealmate-409617.appspot.com",
  messagingSenderId: "990745179184",
  appId: "1:990745179184:web:60c6b305fc5fe537d2a9f5",
  measurementId: "G-KCP80WK9VR"
};

const app = initializeApp(firebaseConfig);

// Uncomment the following line if analytics are needed
// const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();


export default app;